import React, { useEffect, useState } from "react";
import {
  Container,
  useMediaQuery,
  Grid,
  styled,
  Paper,
} from "@material-ui/core";
import SEO from "../components/seo/seo";
import Text from "../components/data-display/text";
import { AiFillCheckCircle } from "react-icons/ai";
import PostTaskImage from "../images/icon_1.png";
import DialogBottomButton from "../components/buttons/dialog-bottom-button";
import useTheme from "@material-ui/core/styles/useTheme";
import { getImage } from "../content/category-icons";
import ReviewCard from "../components/cards/review-card";
import card from "../components/cards/card.json";
import tasks from "../components/cards/task.json";
import SecondaryButton from "../components/buttons/secondary-button";
import { Link } from "gatsby";
import questions from "../content/questions.json";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ScrollingTasks from "../components/home/task-examples/scrolling-tasks";
import { useMatch } from "@reach/router";
import { auth } from "../utils/firebase.utils";
import PostTaskWrapper from "../components/post-task/post-task-wrapper";
import { navigate } from "gatsby";
import firebase from "firebase";
import { UserData } from "../models/user-data";
import InlineLink from "../components/data-display/InlineLink";

const useStyles = makeStyles(() => ({
  buttonPrimaryLight: {
    borderRadius: "160px",
    textAlign: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    boxSizing: "border-box",
    borderWidth: "2px",
    borderStyle: "solid",
    padding: "4px 16px",
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    fontWeight: "initial",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    borderColor: " rgb(231, 235, 251)",
    backgroundColor: "rgb(246, 248, 253)",
    color: "rgb(0, 143, 180)",
    width: "200px",
  },
  loginButtonsPrimaryLight: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(108, 82, 255,0.06)",
    "&:hover": {
      background: "rgba(78, 62, 253,0.13)",
    },
  },
}));

const ServicePage = ({ pageContext: service }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const tasksMatch = useMatch("/services/:page");
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const height = xsDown ? "130px" : "140px";
  let tasksMap = tasks.filter((t) => t.location === tasksMatch?.page);
  const currentUser = auth?.currentUser;
  const classes = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expande, setExpande] = React.useState("panel2");

  const handleChang = (panel) => (event, newExpanded) => {
    setExpande(newExpanded ? panel : false);
  };
  const [expand, setExpand] = React.useState("panel3");

  const handleChan = (panel) => (event, newExpanded) => {
    setExpand(newExpanded ? panel : false);
  };
  const [expandd, setExpandd] = React.useState("panel4");
  const [isAuthenticated, setAuth] = useState(false);
  const [userData, setUserData] = useState<UserData>(null);

  const handleCha = (panel) => (event, newExpanded) => {
    setExpandd(newExpanded ? panel : false);
  };
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let doc = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (doc.exists) {
          let data = doc.data();
          data.uid = user.uid;

          let privateDoc = await firebase
            .firestore()
            .collection("users_stripe")
            .doc(user.uid)
            .get();
          if (privateDoc.exists) {
            data.phoneNumber = privateDoc.data()["phoneNumber"];
            data.birthDate = privateDoc.data()["birthDate"];
          }

          setUserData(new UserData(data));
          setAuth(true);
        }
      } else {
        setAuth(false);
        setUserData(null);
      }
    });
  }, [isAuthenticated]);

  return (
    <>
      <SEO description={service.seoDescription} title={service.seoTitle} />
      <div
        style={{
          width: "100%",
          background: `url(${getImage(service.location)}) ${
            smDown ? "center" : "-8vw"
          } ${smDown ? "0" : "20%"} / ${
            !smDown ? "cover" : "auto 80vh"
          } no-repeat`,
          minHeight: "90vh",
          position: "relative",
        }}
      >
        <div style={{ height: "180px" }} />
        <div
          style={{
            background: "#12011F",
            position: smDown ? "relative" : "absolute",
            right: 0,
            bottom: 0,
            width: smDown ? "100vw" : "30vw",
            marginTop: smDown ? "30vh" : "",
            height: "100%",
            padding: smDown ? "48px 32px" : lgDown ? "96px 48px" : "96px 64px",
          }}
        >
          <Text variant={"h1"} black style={{ color: "white" }}>
            {service?.title ?? ""}
          </Text>
          <div style={{ height: "8px" }} />
          <Text
            style={{ marginBottom: "6px", maxWidth: "80%", color: "white" }}
            variant={lgDown ? "body2" : "h6"}
            regular
          >
            {service?.subtitle ?? ""}
          </Text>
          {(service?.listTile ?? []).map((str, index) => {
            return (
              <div
                style={{
                  height: "auto",
                  padding: lgDown ? "7px 0" : "14px 0px",
                }}
                key={index}
              >
                {str !== "& much more..." && (
                  <AiFillCheckCircle
                    size={20}
                    style={{
                      color: "white",
                      verticalAlign: "middle",
                      marginRight: "24px",
                    }}
                  />
                )}
                <Text
                  variant={"body2"}
                  regular
                  component={"span"}
                  style={{ color: "white" }}
                >
                  {str}
                </Text>
              </div>
            );
          })}
          <div>
            <DialogBottomButton
              to={currentUser ? "/tasks" : "/login"}
              showBackgroundFade={false}
              backgroundColor={theme.palette.primary.main}
              textColor={"white"}
              style={{
                maxWidth: "320px",
                left: "auto",
                marginBottom: smDown ? "15px" : "16px",
                zIndex: 300,
                padding: 0,
                position: "absolute",
                minHeight: "15vh",
                minWidth: "20vh",
              }}
            >
              {service?.buttonText ?? ""}
            </DialogBottomButton>
          </div>
        </div>
      </div>

      <div>
        <Container style={{ height: "600px" }}>
          <div />
          <Grid container>
            <Grid
              item
              xs={6}
              lg={6}
              md={6}
              sm={12}
              style={{
                maxWidth: smDown ? "100%" : "100%",
                flexBasis: smDown ? "100%" : "50%",
              }}
            >
              <div style={{ marginTop: "50px" }}>
                <Text
                  variant={"h2"}
                  black
                  style={{ marginBottom: "10px", width: "100%" }}
                >
                  {service?.headline ?? ""}
                </Text>
                <Text
                  regular
                  variant={"body1"}
                  style={{ marginTop: "10px", maxWidth: "600px" }}
                >
                  {service.description}
                </Text>
                <Text
                  regular
                  variant={"body1"}
                  style={{ maxWidth: "600px", marginTop: "40px" }}
                >
                  {service.description1}
                </Text>
               
                {isAuthenticated ? (
                  <div style={{marginTop:'20px'}}>
                  <PostTaskWrapper>
                    <SecondaryButton
                      background={theme.palette.primary.main}
                      textColor={"white"}
                      style={{ width: "50%"  }}
                    >
                      {" "}
                      Get Free Quotes
                    </SecondaryButton>
                  </PostTaskWrapper>
                  </div>
                ) : (
                  <div style={{marginTop:'20px'}}>
                  <SecondaryButton
                    to={"/login"}
                    background={theme.palette.primary.main}
                    textColor={"white"}
                    style={{ width: "50%" }}
                  >
                    Get Free Quotes
                  </SecondaryButton>
                  </div>
                )}
              </div>
            </Grid>
            {!smDown && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div
                  style={{ marginTop: "40px", paddingLeft: !smDown && "100px" }}
                >
                  <img
                    loading="lazy"
                    alt={"phone"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fsyde5.png?alt=media&token=6b1e0133-6605-4160-bd81-95e66a443abf"
                    }
                    style={{ height: "600px", position: "absolute" }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
      {service.location != "tutoring" && service.location != "computer-it" && (
        <Container style={{ marginTop: "180px" }} maxWidth={"lg"}>
          {service.location != "handyman" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <Text style={{}} black variant={"h2"}>
                Best Rated Local {service.navTitle} Near Me
              </Text>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <Text style={{}} black variant={"h2"}>
                Best Rated Local Handyman Near Me
              </Text>
            </div>
          )}
          <Grid
            container
            style={{ display: "flex", justifyContent: "center" }}
            spacing={xsDown ? 0 : 0}
          >
            {(service.location ? card : card.slice(0, 3)).map((r) => {
              return service.location === r.location ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
                  style={{
                    display: "grid",
                    marginTop: "43px",
                    justifyContent: "center",
                  }}
                >
                  <ReviewCard review={r} />
                </Grid>
              ) : (
                ""
              );
            })}
          </Grid>
          <div style={{ height: "100px" }}></div>
        </Container>
      )}

      <div />

      <div style={{ background: "white" }}>
        <Container>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: smDown ? "0px" : "50px",
            }}
          >
            <Text
              variant={"h2"}
              black
              style={{ marginTop: smDown ? "20px" : "50px" }}
            >
              How It Works
            </Text>
            <div style={{ height: "12px" }} />
          </div>
          <div style={{ height: "20px" }} />
          <Grid
            container
            style={{
              display: smDown ? "grid" : "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "50px",
              justifyContent: "center",
              textAlign: "center",
              gap: "50px",
            }}
          >
            {smDown ? (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ marginBottom: "20px" }}
                >
                  <img
                    loading={"lazy"}
                    alt={"Post A Task"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_1.webp?alt=media&token=daa3a1cd-b39e-4fc1-9dae-0f11814b3fda"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ marginBottom: "40px" }}
                >
                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Post a Task
                  </Text>

                  <Text
                    style={{ width: mdDown ? "100%" : "80%" }}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    Describe the job you need done & post it on Sydetasker in
                    2-3 minutes. Ensure notifications are on to receive alerts
                    about your task.{" "}
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{ marginBottom: "20px" }}>
                  <img
                    loading={"lazy"}
                    alt={"Review Offers"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_2.webp?alt=media&token=85263787-a6f3-4c79-95c0-dc0589ec6542"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ marginBottom: "40px" }}
                >
                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Review Offers
                  </Text>
                  <Text
                    style={{ width: mdDown ? "100%" : "80%" }}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    View offers from local, verified, & background checked
                    Taskers. Accept and assign your top choice to book your
                    service.{" "}
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{ marginBottom: "20px" }}>
                  <img
                    loading={"lazy"}
                    alt={"Complete Task"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_4.png?alt=media&token=6d60a209-5832-4207-a427-b3acec26ac8a"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} style={{}}>
                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Complete Task
                  </Text>
                  <Text
                    style={{ width: mdDown ? "100%" : "80%" }}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    Discuss the details with your Tasker in our chat feature
                    where you can safely communicate without giving any private
                    information. What’s next on your list!?{" "}
                  </Text>
                </Grid>
              </Grid>
            ) : (
              <>
                {" "}
                <Grid item xs={12} lg={3} style={{ marginBottom: "20px" }}>
                  <img
                    loading={"lazy"}
                    alt={"Post a Task"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_1.webp?alt=media&token=daa3a1cd-b39e-4fc1-9dae-0f11814b3fda"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />

                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Post a Task
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    Describe the job you need done & post it on Sydetasker in
                    2-3 minutes. Ensure notifications are on to receive alerts
                    about your task.
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{ marginBottom: "20px" }}>
                  <img
                    loading={"lazy"}
                    alt={"Review Offers"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_2.webp?alt=media&token=85263787-a6f3-4c79-95c0-dc0589ec6542"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />
                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Review Offers
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    View offers from local, verified, & background checked
                    Taskers. Accept and assign your top choice to book your
                    service.{" "}
                  </Text>
                </Grid>{" "}
                <Grid item xs={12} lg={3} style={{ marginBottom: "25px" }}>
                  <img
                    loading={"lazy"}
                    alt={"Complete Task"}
                    src={
                      "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_4.png?alt=media&token=6d60a209-5832-4207-a427-b3acec26ac8a"
                    }
                    height={xsDown ? "80px" : "120px"}
                  />
                  <Text
                    variant={"body1"}
                    style={{ marginTop: "10px" }}
                    bold
                    component={"p"}
                  >
                    Complete Task
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    Discuss the details with your Tasker in our chat feature
                    where you can safely communicate without giving any private
                    information. What’s next on your list!?{" "}
                  </Text>
                </Grid>
              </>
            )}
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <SecondaryButton
                to={
                  "/support/posters/post-a-task/before-posting/how-do-i-post-a-task"
                }
                background={theme.palette.primary.main}
                textColor={"white"}
                style={{ width: "80%" }}
              >
                Sydetasker Help Center
              </SecondaryButton>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ height: smDown ? "100px" : "100px" }} />
      <div style={{ padding: "100px 0", position: "relative" }}>
        <Container maxWidth={"lg"}>
          {mdDown ? (
            <Grid
              container
              direction="column"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item lg={12}>
                <Text
                  variant={"h2"}
                  black
                  style={{
                    paddingBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Ready To Hire {service.question}?
                </Text>

                <SecondaryButton
                  to={"/login"}
                  background={theme.palette.primary.main}
                  textColor={"white"}
                  style={{ width: mdDown ? "80%" : "100%" }}
                >
                  <Text variant={"body2"} style={{ color: "white" }}>
                    {service?.buttonText ?? ""}
                  </Text>
                </SecondaryButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" style={{}}>
              <Grid item lg={6}>
                <img
                  loading="lazy"
                  alt={"Tasker"}
                  src={
                    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fsydeman.png?alt=media&token=e46be873-896e-4d34-a5c2-522200001fea"
                  }
                  style={{
                    height: "550px",
                    position: "absolute",
                    bottom: "0px",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <Grid container direction="column">
                  <Grid item lg={11}>
                    <Text
                      variant={"h2"}
                      black
                      style={{
                        paddingBottom: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Ready To Hire {service.question}?
                    </Text>

                    <SecondaryButton
                      to={"/login"}
                      background={theme.palette.primary.main}
                      textColor={"white"}
                      style={{}}
                    >
                      <Text variant={"body2"} style={{ color: "white" }}>
                        {service?.buttonText ?? ""}
                      </Text>
                    </SecondaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>

      {service.location != "admin" &&
        service.location != "photography" &&
        service.location != "tutoring" &&
        service.location != "computer-it" && (
          <div style={{ background: "white" }}>
            <Container maxWidth={"lg"}>
              <div style={{ height: "100px" }} />
              {service.location != "handyman" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "50px",
                  }}
                >
                  <Text style={{ marginBottom: "40px" }} variant={"h2"}>
                    Recent {service.navTitle} Tasks
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "50px",
                  }}
                >
                  <Text style={{ marginBottom: "40px" }} variant={"h2"}>
                    Recent Handyman Tasks
                  </Text>
                </div>
              )}
            </Container>
            <ScrollingTasks tasks={tasksMap} reverse />
            <div style={{ height: "100px" }} />
          </div>
        )}
      {!service.hideQues && (
        <div
          style={{
            background: service.hideQues ? "white" : "",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "150px" }} />
          <Text
            style={{
              marginBottom: "30px",
              display: "flex",
              textAlign: "center",
            }}
            variant={"h2"}
          >
            Frequent Questions About {service.navTitle}{" "}
          </Text>
          <Container maxWidth={"lg"}>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {questions.map((q) => {
                return (
                  service.location === q.location && (
                    <Grid item xs={12} sm={12} xl={5} lg={5} style={{}}>
                      {service.location === q.location &&
                        q.type === "question1" && (
                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            style={{
                              backgroundColor: "#F7F8FB",
                              width: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <img
                                  loading="lazy"
                                  src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v2.png"
                                  alt="Question"
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{q.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginBottom: "55px" }}>
                              <Typography>{q.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      {service.location === q.location &&
                        q.type === "question2" && (
                          <Accordion
                            expanded={expande === "panel2"}
                            onChange={handleChang("panel2")}
                            style={{
                              backgroundColor: "#F7F8FB",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <img
                                  loading="lazy"
                                  src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v2.png"
                                  alt="Question"
                                />
                              }
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                            >
                              <Typography>{q.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginBottom: "5px" }}>
                              <Typography>{q.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      {service.location === q.location &&
                        q.type === "question3" && (
                          <Accordion
                            expanded={expand === "panel3"}
                            onChange={handleChan("panel3")}
                            style={{
                              backgroundColor: "#F7F8FB",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <img
                                  loading="lazy"
                                  src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v2.png"
                                  alt="Question"
                                />
                              }
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{q.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{q.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      {service.location === q.location &&
                        q.type === "question4" && (
                          <Accordion
                            expanded={expandd === "panel4"}
                            onChange={handleCha("panel4")}
                            style={{
                              backgroundColor: "#F7F8FB",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <img
                                  loading="lazy"
                                  src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v2.png"
                                  alt="Question"
                                />
                              }
                              aria-controls="panel4d-content"
                              id="panel4d-header"
                            >
                              <Typography>{q.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginBottom: "23px" }}>
                              <Typography>{q.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                    </Grid>
                  )
                );
              })}
              <div style={{ height: "50px" }}></div>
              {service.buttonText == "Find a Heavy Lifter" && (
                <div style={{ height: "50px" }}></div>
              )}
            </Grid>
          </Container>
          <div style={{ height: "100px" }} />
        </div>
      )}

      <div
        style={{ height: "1px", width: "100%", background: "rgb(220,220,220)" }}
      />
    </>
  );
};

export default ServicePage;
